<template>
  <div>
    <ModalComponent
        title="在庫一覧"
        color="warning"
        size="xl"
        :show.sync="filterItemModal"
        :footer=false
    >
      <FilterItemStock @closeModel="closeModelFilterItem"
                       @submitData="filterItemStock"
                       :listItemArray.sync="itemIdChose"
                       :listItemArrayShow.sync="itemIdRemove">
      </FilterItemStock>
    </ModalComponent>
    <ModalComponent
        title="QRコードスキャン"
        color="warning"
        :show.sync="openScanQR"
        @removeItemScan="removeItemScan"
        :footer=false
    >
      <CRow v-if="openScanQR" class="text-center">
        <div class="w-100">
          <div>
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" style="text-align: center">
              <div style="padding-top: 80px">
                <div v-if="result" style="color: #22ff34;font-size: 35px; white-space: break-spaces; line-height:70px;" >{{ result }}</div>
                <div v-if="resultError" class="text_result">{{ resultError }}</div>
              </div>
            </qrcode-stream>

          </div>
        </div>
      </CRow>
    </ModalComponent>
    <ModalComponent
      title="ハンディでQRを読み取る"
      color="warning"
      :footer="false"
      :show.sync="openHandiQR">
      <div class="p-3">
        <CRow>
          <CCol md="12">
            <div class="handy_status">
              <div v-if="handyProcessing"  class="badge bg-secondary">処理中</div>
              <div v-if="!handyProcessing" class="badge bg-success">読取可能</div>
            </div>
            <div v-if="handyText" class="handy_message" >{{ handyText }}</div>
            <div v-if="result" class="handy_message">{{ result }}</div>
          </CCol>
        </CRow>
      </div>
    </ModalComponent>
    <div class="text-sm-right mb-2">
      <CButton color="primary" class="mr-1" @click="openFilterItemModal">
        在庫から選択
      </CButton>
      <CButton color="primary" class="mr-1" @click="() => handleOpenScanQR(true)">
        QRコードをスキャンして登録する
      </CButton>
      <CButton color="primary" @click="openHandiQR = true">
        ハンディでQRを読み取る
      </CButton>

    </div>
    <div v-if="cardboardIdDefault">
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
    <div>
      <!--            {{itemMasters}}-->
      <CDataTable
          hover
          striped
          border
          :itemsPerPage="200"
          :items="itemMasters"
          :fields="fields"
      >
        <td slot="delete" slot-scope="{item, index}">
          <div>
            <CButtonClose @click="() => handleDelete(item)"/>
          </div>
        </td>
        <td slot="cost" slot-scope="{item}" class="text-right">
          <div>
            {{ item.cost }}
          </div>
        </td>
        <td slot="price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.price }}
          </div>
        </td>
        <td slot="retail_price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.retail_price }}
          </div>
        </td>
      </CDataTable>
    </div>
    <CRow class="text-center">
      <CCol md="6">
        <CButton v-if="!cardboardIdDefault" style="height: 50px" color="success" @click="addItemForCardboard">
          上記内容で登録する
        </CButton>
        <CButton v-if="cardboardIdDefault" style="height: 50px" color="success" @click="confirmUpdateItemForCardboard">
          登録
        </CButton>
      </CCol>
      <CCol md="6">
        <CButton v-if="!cardboardIdDefault" class="mr-2" style="height: 50px" color="primary" @click="goBack">
          キャンセル
        </CButton>
        <CButton v-if="cardboardIdDefault" class="mr-2" style="height: 50px" color="primary"
                 @click="() => $router.back()">
          キャンセル
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import apiStock from '../../api/stockApi'
import {SCREEN_KEY} from "@/utils/constance";
import ModalComponent from "@/components/views/ModalComponent";
import FilterItemStock from "@/views/cardboards/FilterItemStock";
import {numberWithCommas} from "@/utils/utils";
import ResourceApi from "@/api/resourceApi";
import Vue from "vue";

const cardboardApi = new ResourceApi('cardboard');
export default {

  components: {ModalComponent, QrcodeStream, FilterItemStock},

  data() {
    return {
      result: '',
      resultError: '',
      currentItemMaster: '',
      error: '',
      lastItemId: '',
      typeSelected: 1,
      itemMasters: [],
      itemIds: [],
      openScanQR: false,
      openHandiQR: false,
      userSales: [],
      fields: [
        {key: 'item_id', label: '商品ID'},
        {key: 'arrived_at', label: '入荷日'},
        {key: 'shelf', label: '棚名'},
        {key: 'name', label: '商品名'},
        {key: 'size', label: 'サイズ'},
        {key: 'color', label: '色'},
        {key: 'cost', label: '仕入原価'},
        {key: 'price', label: '販売価格'},
        {key: 'retail_price', label: 'プロパー上代'},
        {key: 'delete', label: '削除'},
      ],
      cardboardIdDefault: this.$route.params.id,
      filterItemModal: false,
      itemIdChose: [],
      itemIdRemove: [],
      handyItemId : "",
      handyProcessing : false,
      handyText: "",
      camera: 'auto',
    }
  },

  created() {
    this.getDataDefault()
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },

  methods: {
    turnCameraOn() {
      this.camera = 'auto'
    },
    turnCameraOff() {
      this.camera = 'off'
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    },

    onDecode(result) {
      this.lastItemId = result
      this.turnCameraOff()
      this.result = ''
      this.itemIds.push(result)
      this.getItem(result).then((response) =>{
        setTimeout(() => {
          this.handleAddItem()
          this.turnCameraOn()
        }, 1000)      
      })
    },
    addItemForCardboard() {
      let params = {
        items: this.itemMasters,
        screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE
      }
      this.$emit('addItemForCardboard', params)
    },
    handleOpenScanQR(value) {
      this.openScanQR = value;
      this.turnCameraOn();
    },
    handleDelete(item) {
      this.itemMasters = this.itemMasters.filter(i => i.id !== item.id)
      this.itemIdChose = this.itemIdChose.filter(i => i !== item.item_id)
      this.itemIdRemove.push(item.item_id)
      // this.itemMasters.splice(index)
    },

    handleAddItem() {
      let data = this.currentItemMaster;
      if (!data) {
        this.resultError = '';
        this.result = '';
        return
      }
      let itemMaster = data.item && data.item.item_master ? data.item.item_master : {}
      let item = {
        id: data.id,
        item_id: data.item_id,
        arrived_at: data.arrived_at,
        shelf: data.shelf ? data.shelf.name : '',
        name: itemMaster.name,
        size: itemMaster.size.name,
        color: itemMaster.color.color_code + ':' + itemMaster.color.name,
        cost: itemMaster.cost ? numberWithCommas(itemMaster.cost) : '',
        price: data.price ? numberWithCommas(data.price) : numberWithCommas(itemMaster.price),
        retail_price: itemMaster.retail_price ? numberWithCommas(itemMaster.retail_price) : ''
      }
      if (!this.containsObject(item)) {
        this.itemIdChose.push(item.item_id)
        this.itemMasters.push(item)
        this.itemIdRemove = this.itemIdRemove.filter(i => i !== item.item_id)
        this.result = ''
        this.resultError = ''
      } else {
        this.result = '';
        this.resultError = '製品が追加されました。';
      }
      this.currentItemMaster = '';
    },

    async getItem(item_id) {
      return await apiStock.itemScanQR({item_id, screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE}).then(response => {
        let data = response.data;
        if (!data) {
          this.result = '';
          this.resultError = 'スキャンした商品は存在しません。';
          return
        }
        this.currentItemMaster = data;
        this.result = 'スキャンが完了しました。 \n 商品ID：' + data.item_id+ '\n 商品コード：' + data.item.item_master.item_code
        this.resultError = ''
        // this.loading = false
      }).catch(error => {
        this.resultError = 'この商品は既に段ボールに格納されています。'
        // this.loading = false
      })
    },
    async getItem2(item_id) {
      return await apiStock.itemScanQR({item_id, screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE})
    },
    containsObject(obj) {
      let x = false;

      this.itemMasters.map(i => {
        if (i.id === obj.id) {
          x = true;
        }
      })

      return x;
    },
    openFilterItemModal() {
      this.filterItemModal = true
    },
    closeModelFilterItem() {
      this.filterItemModal = false
    },

    filterItemStock(val) {
      this.filterItemModal = false
      if (!val.list_id_str.length) {
        return;
      }
      // let listItemIds = JSON.parse("[" + val.list_id_str + "]");
      let listItemIdsStr = val.list_id_str
      apiStock.getItemByFilter({list_id_str: listItemIdsStr}).then(response => {
        let data = response.data
        data.map(item => {
          this.currentItemMaster = item;
          this.handleAddItem();
        })
      }).catch(error => {
      })
    },
    goBack() {
      this.$emit('goBack');
    },
    getDataDefault() {
      const id = this.$route.params.id
      if (!id) {
        return
      }
      cardboardApi.show({id: id}).then(response => {
        let data = response.data.items
        data.map(dt => {
          this.handleDataDefault(dt)
        })
      })
    },
    handleDataDefault(data) {
      let item = {
        id: data.item.stock.id,
        item_id: data.item_id,
        arrived_at: data.item.stock.instock_date,
        shelf: data.item.stock.shelf.name,
        name: data.item.item_master.name,
        size: data.item.item_master.size.name,
        color: data.item.item_master.color.color_code + ':' + data.item.item_master.color.name,
        cost: numberWithCommas(data.item.item_master.cost),
        price: data.item.stock.price ? numberWithCommas(data.item.stock.price) : numberWithCommas(data.item.item_master.price),
        retail_price: numberWithCommas(data.item.item_master.retail_price)
      }
      this.itemIdChose.push(item.id)
      this.itemMasters.push(item)
    },
    onKeyDown(ev) {
      if(!this.openHandiQR || this.handyProcessing) {
        return;
      }
      if(ev.key == "Enter") {
        var self = this
        this.handyText = '商品ID：' + this.handyItemId + "をスキャン処理中"
        this.handyProcessing = true
        this.getItem2(this.handyItemId).then((response) =>{
          self.handyItemId = ""
          self.handyText = ""
          var data = response.data
          if(data) {
            self.result = 'スキャンが完了しました。 \n 商品ID：' + data.item_id+ '\n 商品コード：' + data.item.item_master.item_code
          } else {
            self.result = 'スキャンした商品は存在しません'
          }
          setTimeout(() => {
            self.handyProcessing = false
          }, 500)
          setTimeout(() => {
            self.currentItemMaster = response.data
            self.handleAddItem()
          }, 3000)
        });
        return;
      } else {
        this.handyItemId += ev.key
      }
    },    
    confirmUpdateItemForCardboard() {
      this.$confirm(
          {
            title: '確認',
            message: '更新しますか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                this.updateItemForCardboard()
              }
            }
          }
      )
    },
    updateItemForCardboard() {
      // console.log(7777777777,this.itemMasters)
      if (!this.itemMasters.length) {
        Vue.$toast.error('少なくとも1つの製品が必要です')
        return;
      }
      let params = {
        items: this.itemMasters,
        id: this.cardboardIdDefault
      }
      this.loading = true
      cardboardApi.edit(params).then(response => {
        if (this.cardboardIdDefault) {
          Vue.$toast.success('更新は成功しました。')
        } else {
          Vue.$toast.success('登録に成功しました。')
        }
        setTimeout(this.$router.back(), 2000);
      }).catch(error => {
        Vue.$toast.error('エラー')
      }).finally(() => {
        this.loading = false
      })
    },
    removeItemScan() {
      this.turnCameraOn();
      this.result = '';
      this.resultError = '';
      this.currentItemMaster = '';
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}

.text_result {
  font-size: 40px;
  color: red;
}

.handy_status {
  text-align: center;
  color: white;
  margin-bottom: 8px;
}
.handy_message {
  color: black;
  white-space: break-spaces;
}
</style>
