<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          在庫
        </CCardHeader>
<!--        <vue-confirm-dialog></vue-confirm-dialog>-->
        <CCardBody>
          <div>
            <CRow>
              <CCol sm="4">
                <SelectComponent id="year" label="年度" :default-options="businessYearOptions" @change="changeBusinessYear"
                                 :default-value="businessYear" placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="month" label="展開月" :default-options="businessMonthOptions" @change="changeBusinessMonth"
                                 :default-value="businessMonth" placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="shelf" label="棚" end-point="shelves" @change="changeShelves" :default-value="shelvesId"
                                 placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="color" label="色" :default-options="colorOptions" @change="changeColor" :default-value="colorId"
                                 placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="size" label="サイズ" end-point="sizes" @change="changeSize" :default-value="sizeId"
                                 placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="season" label="シーズン" end-point="seasons" @change="changeSeasons" :default-value="season"
                                 placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="ppbs" label="PP/BS" :default-options="ppbsOptions" @change="changePPBS" :default-value="ppbs"
                                 placeholder="選択する"/>
              </CCol>
              <CCol sm="4">
                <SelectComponent id="majorcategory" label="大分類" end-point="major-categories" @change="changeMajorCategory"
                                 :default-value="majorCategoryId" placeholder="選択する"
                />
              </CCol>
              <CCol sm="4">
                <SelectComponent id="middlecategory" label="中分類" end-point="middle-categories" @change="changeMiddleCategory"
                                 :default-value="middleCategoryId" placeholder="選択する"
                />
              </CCol>
              <CCol sm="4">
                <SelectComponent id="minorcategory" label="小分類" end-point="minor-categories" @change="changeMinorCategory"
                                 :default-value="minorCategoryId" placeholder="選択する"
                />
              </CCol>
              <CCol sm="4">
                <CInput id="itemcode" label="品番" type="text" horizontal v-model="itemCode" @keypress.enter="changeItemCode" @blur="changeItemCode" />
              </CCol>
              <CCol sm="4">
                <CInput id="itemid" label="商品ID" type="text" horizontal v-model="itemId" @keypress.enter="changeItemId" @blur="changeItemId" />
              </CCol>
            </CRow>
          </div>
          <!--            SCREEN_KEY.LIST_RETURN-->
          <div v-if="loading">
            <LoadingCompoment/>
          </div>
          <div v-else :key="key">
            <DataTable
                hover
                striped
                border
                :items="stocks"
                :fields="fields"
                :items-per-page="perPage"
                :active-page="page"
                :pagination-data="paginationData"
                @page-change="pageChange"
                @pagination-change="selectChangePerpage"
                :pagination="{ doubleArrows: false, align: 'center'}"
                :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50],
              }"
            >
<!--              <div slot="select-header">-->
<!--                <CInputCheckbox-->
<!--                    label=""-->
<!--                    class="pb-3"-->
<!--                    name="listSelected"-->
<!--                    @update:checked="() => handleCheckAll()"-->
<!--                    :checked="checkAll"-->
<!--                />-->
<!--              </div>-->
              <td slot="select" slot-scope="{item}">
                <div>
                  <CInputCheckbox
                      :key="item.id"
                      label=""
                      :value="item.item"
                      name="listSelected"
                      @update:checked="() => handleSelect(item)"
                      :checked="listSelected.includes(item.id)"
                  />
                </div>
              </td>
<!--              <td slot="status" slot-scope="{item}">-->
<!--                <div> {{ item.status_new }}</div>-->
<!--              </td>-->
              <td slot="item_masters_code" slot-scope="{item}">
                <div>{{ item.item.item_master.item_code || '' }}</div>
              </td>
              <td slot="item_masters_name" slot-scope="{item}">
                <div>{{ item.item.item_master.name || '' }}</div>
              </td>
              <td slot="item_masters_size" slot-scope="{item}">
                <div>{{ item.item.item_master.size.name || '' }}</div>
              </td>
              <td slot="item_masters_color" slot-scope="{item}">
                <div>{{ item.item_masters_color_name || '' }}</div>
              </td>
              <td slot="item_masters_cost" slot-scope="{item}" class="text-right">
                <div>{{ item.item_masters_cost || '' }}</div>
              </td>
              <td slot="item_masters_price" slot-scope="{item}" class="text-right">
                <div>{{ item.price || '' }}</div>
              </td>
              <td slot="item_masters_retail_price" slot-scope="{item}" class="text-right">
                <div>{{ item.item_masters_retail_price || '' }}</div>
              </td>
            </DataTable>

          </div>
        </CCardBody>
        <CCardFooter>
          <CRow class="mb-3">
            <CCol sm="2">
              <CButton color="success" class="mr-2" @click="submitData">選択</CButton>
              <CButton color="primary" @click="closeModel">取り消す</CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {SCREEN_KEY, STOCK_STATUS} from "@/utils/constance";
import ResourceApi from '../../api/resourceApi';
import Vue from "vue";
import DataTable from "@/components/DataTable";
import Stocks from "@/views/stocks/StockFields";
import SelectComponent from "@/components/views/SelectComponent";
import {setOptionBusinessMonth, setOptionBusinessYear, numberWithCommas, setOptionColors} from "@/utils/utils";

const apiStocks = new ResourceApi('stocks');
export default {
  name: 'FilterItemStock',
  props:{
    listItemArray: Array,
    listItemArrayShow: Array,
  },
  components: {SelectComponent, DataTable},
  data() {
    return {
      stockStatus: STOCK_STATUS,
      stocks: [],
      loading: false,
      checkAll: false,
      filterDate: '',
      shelvesId: '',
      businessYear: '',
      businessMonth: '',
      categories: {},
      categorySelected: '',
      season: '',
      colorId: '',
      sizeId: '',
      userShopId: this.$store.getters.user ? this.$store.getters.user.shop_id : '',
      listSelected: [],
      shopOptions: [],
      statusSelected: '',
      typeDataSelected: 1,
      shopSelected: '',
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {page: this.page, per_page: this.perPage, screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE, type_item: 'LIST_ALL'},
      key: Math.random(),
      keyCategory: Math.random(),
      businessYearOptions: [],
      businessMonthOptions: [],
      colorOptions: [],
      ppbs: '',
      ppbsOptions: [
        {label: '選択する', value: ''},
        {label: 'PP', value: 'PP'},
        {label: 'BS', value: 'BS'},
      ],

      options: [
        {label: '選択する', value: ''},
        {label: '入庫', value: 1},
        {label: '箱詰め', value: 2},
        {label: '出荷済', value: 3},
      ],
      dateOptions: [{label: '入荷日', value: 2}, {label: '出荷日', value: 3}],
      optionsPerPage: [10, 20, 30, 40, 50, 100],
      fields: [
        { key: 'select', label: 'チェック' },
        { key: 'item_id', label: '商品ID' },
        { key: 'instock_date', label: '入荷日' },
        // { key: 'status', label: 'ステータス' },
        { key: 'shelf_id', label: '棚ID' },
        { key: 'item_masters_code', label: '品番' },
        { key: 'item_masters_name', label: '品名' },
        { key: 'item_masters_size', label: '製品サイズ' },
        { key: 'item_masters_color', label: 'カラー名' },
        { key: 'item_masters_cost', label: '仕入原価' },
        { key: 'item_masters_price', label: '販売価格' },
        { key: 'item_masters_retail_price', label: 'プロパー上代' },
        // { key: 'edit', label: '' },
      ],
      activePage: 1,
      majorCategoryId: '',
      middleCategoryId: '',
      minorCategoryId: '',
      itemCode: '',
      itemId: ''
    }
  },
  watch: {
    listSelected: function (newVal, oldVal) {
      let checkAll = true;
      this.stocks.map(s => {
        if (!newVal.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    },
    stocks: function (newVal, oldVal) {
      let checkAll = true;
      this.stocks.map(s => {
        if (!this.listSelected.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    },

    listItemArray: function (newVal, oldVal) {
      let params = this.getParams({list_not_show: newVal})
      this.getData(params)
    },

    listItemArrayShow: function (newVal, oldVal) {
      // console.log(33333333333333, newVal)
      let params = this.getParams({list_need_show: newVal})
      this.getData(params)
    }
  },
  created() {
    this.businessYearOptions = setOptionBusinessYear();
    this.businessMonthOptions = setOptionBusinessMonth();
    this.colorOptions = setOptionColors();
    this.getData();
  },
  methods: {
    getData(params) {
      // this.resetSelected()
      this.loading = true
      this.key = Math.random()
      this.query = {...this.query, ...params}
      let query = {...this.query, ...params}
      apiStocks.index(query).then(response => {
        let stocks = response.data.data;
        stocks.map(s => {
          s.item_masters_cost = numberWithCommas(s.item.item_master.cost);
          s.item_masters_price = numberWithCommas(s.item.item_master.price);
          s.item_masters_retail_price = numberWithCommas(s.item.item_master.retail_price);
          s.item_masters_color_name = s.item.item_master.color.color_code + ':' + s.item.item_master.color.name
          if(s.price) {
              s.price = numberWithCommas(s.price)
            } else {
              s.price = numberWithCommas(s.item.item_master.price)
            }
        })
        // this.listItemArray.map(itemId => {
        //   stocks = stocks.filter(i => i.id !== itemId)
        // })
        this.stocks = stocks;
        this.paginationData = response.data;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },

    handleCheckAll(val) {
      let listSelected = this.listSelected
      if (this.checkAll) {
        this.stocks.map(s => {
          listSelected = listSelected.filter(l => s.item_id !== l)
        })
      } else {
        this.stocks.map(s => {
          listSelected.push(s.item_id)
        })
      }
      this.listSelected = listSelected;
    },

    handleSelect(item) {
      if (this.listSelected.includes(item.item_id)) {
        this.listSelected = this.listSelected.filter(s => s !== item.item_id)
      } else {
        this.listSelected.push(item.item_id)
      }
    },
    setDateFilter(e) {
      this.filterDate = new Date(e.target.value).getTime()
    },

    filterData() {
      this.page = 1;
      let params = this.getParams({page: 1});
      this.getData(params);
    },

    getParams(param) {
      let params = {
        type_date: this.typeDataSelected || null,
        status: this.statusSelected || null,
        date_filter: this.filterDate || null,
        business_year: this.businessYear,
        season_id: this.season,
        shelf_id: this.shelvesId,
        month: this.businessMonth,
        ppbs: this.ppbs,
        size_id: this.sizeId,
        color_id: this.colorId,
        major_category: this.majorCategoryId,
        middle_category: this.middleCategoryId,
        minor_category: this.minorCategoryId,
        list_not_show: this.listItemArray,
        list_need_show: this.listItemArrayShow,
        item_code: this.itemCode,
        item_id: this.itemId
      }
      return {...params, ...param}
    },

    resetFilterData() {
      this.statusSelected = '';
      this.typeDataSelected = '';
      this.filterDate = '';
      this.shelvesId = '';
      this.businessYear = '';
      this.season = '';
      this.businessMonth = '';
      this.ppbs = '';
      this.sizeId = '';
      this.colorId = '';
      this.listSelected = [];
      this.majorCategoryId = '';
      this.middleCategoryId = '';
      this.minorCategoryId = '';
      this.itemId = '';
      this.itemCode = '';
      let params = {
        status: '', date_filter: '', shelves_id: '', business_year: '',
        category_id: '', season: '', major_category: '', middle_category: '', minor_category: ''
      }
      this.getData(params);
    },
    selectTypeDataChange(val) {
      this.typeDataSelected = val ? parseInt(val) : ''
    },
    changeShelves(val) {
      this.shelvesId = val ? parseInt(val) : ''
      let params = this.getData({shelf_id: this.shelvesId})
      this.getData(params)
    },
    changeSeasons(val) {
      this.season = val ? parseInt(val) : ''
      let params = this.getData({season_id: this.season})
      this.getData(params)
    },

    changeColor(val) {
      this.colorId = val ? parseInt(val) : ''
      let params = this.getData({color_id: this.colorId})
      this.getData(params)
    },
    changeSize(val) {
      this.sizeId = val ? parseInt(val) : ''
      let params = this.getData({size_id: this.sizeId})
      this.getData(params)
    },
    changePPBS(val) {
      this.ppbs = val
      let params = this.getData({ppbs: this.ppbs})
      this.getData(params)
    },
    changeBusinessYear(val) {
      this.businessYear = val ? parseInt(val) : ''
      let params = this.getParams({business_year: this.businessYear})
      this.getData(params)
    },
    changeBusinessMonth(val) {
      this.businessMonth = val ? parseInt(val) : ''
      let params = this.getParams({month: this.businessMonth})
      this.getData(params)
    },
    changeMajorCategory(val) {
      this.majorCategoryId = val
      let params = this.getParams({major_category: this.majorCategoryId})
      this.getData(params)
    },
    changeMiddleCategory(val) {
      this.middleCategoryId = val
      let params = this.getParams({middle_category: this.middleCategoryId})
      this.getData(params)
    },
    changeMinorCategory(val) {
      this.minorCategoryId = val
      let params = this.getParams({minor_category: this.minorCategoryId})
      this.getData(params)
    },
    changeItemId() {
      let params = this.getParams({item_id: this.itemId})
      this.getData(params)
    },
    changeItemCode() {
      let params = this.getParams({item_code: this.itemCode})
      this.getData(params)
    },

    selectChangePerpage(val) {
      this.perPage = val ? parseInt(val) : ''
      this.getData({per_page: val});
    },

    pageChange(val) {
      this.page = val;
      this.getData({page: val});
    },
    submitData() {
      let list = this.listSelected
      let listStr = list.join()
      if (!listStr.length) {
        Vue.$toast.error('商品を選択していません。')
      }

      let params = this.getParams({list_id_str: listStr})
      this.resetFilterData()
      this.$emit('submitData', params)
    },
    closeModel() {
      this.resetFilterData();
      this.$emit('closeModel');
    }
  }
}
</script>

<style>
/*.select-shop select{*/
/*  width: 400px !important;*/
/*}*/

@media screen and (min-width: 1500px) {
  .select-shop select {
    width: 400px !important;
  }
}
</style>
