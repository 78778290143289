const Stocks = {
  fields() {
    return [
      { key: "select", label: "" },
      { key: "item_id", label: "商品ID" },
      { key: "instock_date", label: "入荷日" },
      { key: "shop_name", label: "店舗" },
      // { key: 'stock_in_date', label: '入荷日' },
      // { key: 'stock_out_date', label: '出荷日' },
      { key: "status", label: "ステータス" },
      { key: "shelf_id", label: "棚" },
      { key: "item_masters_code", label: "品番" },
      { key: "item_masters_name", label: "品名" },
      { key: "item_masters_size", label: "製品サイズ" },
      { key: "item_masters_color", label: "カラー名" },
      { key: "pp_bs_code", label: "PP/BS" },
      { key: "is_special", label: "優待" },
      { key: "item_masters_cost", label: "仕入原価" },
      { key: "item_masters_price", label: "販売価格" },
      { key: "item_masters_retail_price", label: "プロパー上代" },
      { key: "action", label: "" },
    ]
  },
  // headerColspan(){
  //     return [
  //         {label: '', rowSpan: 2, key: 'select'},
  //         {label: 'ステータス', rowSpan: 2, key: 'status'},
  //         {label: 'Stock', colSpan: 2, key: 'stock',
  //             children: [
  //                 { key: 'item_id', label: '商品ID' },
  //                 { key: 'stock_in_date', label: '入荷日' },
  //                 { key: 'stock_out_date', label: '出荷日' },
  //             ]
  //         },
  //     ]
  // }
}
export default Stocks
